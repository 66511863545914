import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "aside-tautan-link",
  class: "add-sidebar-tautan-link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.formModel,
    rules: _ctx.formRules,
    ref: "formRef",
    "label-position": "left",
    "label-width": "100px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form_item, {
          label: "URL",
          prop: "url"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formModel.url,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.url) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Page",
          prop: "page"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formModel.page,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.page) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Tenant",
          prop: "tenants"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.formModel.tenants,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.tenants) = $event)),
              class: "w-100",
              filterable: "",
              multiple: "",
              placeholder: "Pilih Tenant"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant, tKey) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: tKey,
                    label: tenant,
                    value: tenant
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_button, {
        class: "w-100",
        type: "primary",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitForm(_ctx.formRef)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.save")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}