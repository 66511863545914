
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {
  setCurrentPageTitle,
  setCurrentPageBreadcrumbs,
} from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { checkPermission } from "@/core/helpers/helps";
import {
  DashLinkDrawerMode,
  IDashboardLink,
} from "@/store/modules/DashboardLinkModule";
import ComponentAddNewDashboardLinkForm from "@/components/forms/AddNewDashboardLinkForm.vue";
import ComponentEditDashboardLinkForm from "@/components/forms/EditDashboardLinkForm.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { ElMessage } from "element-plus";
// set tutor
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default defineComponent({
  name: "DashboardLink",
  components: {
    ComponentAddNewDashboardLinkForm,
    ComponentEditDashboardLinkForm,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    setCurrentPageTitle(t("dashboardLink"));
    setCurrentPageBreadcrumbs(("dashboardLink"), [
          {title:("common.setting"), path: '/setting'},
          {title:("masterData"), path: '/setting/master-data/template'},
    ]);

    const isFetching = computed(() => store.getters.dashboardLinkIsFetching);
    const isSubmitting = computed(
      () => store.getters.dashboardLinkIsSubmitting
    );
    const drawerState = computed({
      get: () => store.getters.dashboardLinkDrawerState,
      set: (val) =>
        store.dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE, val),
    });
    const drawerMode = computed(() => store.getters.dashboardLinkDrawerMode);
    const handleDrawerClose = (done) => {
      store.dispatch(
        Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE,
        DashLinkDrawerMode.DEFAULT
      );
      selectedData.value = null;
      done();
    };

    const openDrawer = (data, mode) => {
      store
        .dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE, mode)
        .then(() => {
          switch (mode) {
            case "add":
              store.commit(storeTutorial.Mutations.FILTER_STEP, {
                name: "dashboard-link/add-modal",
                bool: true,
              } as Tutorial.localStorage);
              break;
            case "edit":
              store.commit(storeTutorial.Mutations.FILTER_STEP, {
                name: "dashboard-link/edit-modal",
                bool: true,
              } as Tutorial.localStorage);
              break;
          }
        });
      store.dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE, true);
      selectedData.value = data;
    };
    const tableData = computed(() => store.getters.dashboardLinkData);
    const headerCellClassName = () => {
      return "bg-secondary";
    };
    onMounted(() => {
      store.commit(storeTutorial.Mutations.FILTER_STEP, {
        name: "setting-dashboard-link",
        bool: false,
      } as Tutorial.localStorage);
      store.dispatch(Actions.GET_DASHBOARD_LINK_LIST).then(() => {
        store.commit(storeTutorial.Mutations.FILTER_STEP, {
          name: "setting-dashboard-link",
          bool: true,
        } as Tutorial.localStorage);
      });
    });
    const selectedData = ref<IDashboardLink | null>(null);
    const dialogState = ref(false);
    const openDialog = (data) => {
      selectedData.value = data;
      dialogState.value = true;
    };
    const closeDialog = () => {
      dialogState.value = false;
      selectedData.value = null;
    };
    const remove = (id) => {
      store
        .dispatch(Actions.SOFT_DELETE_DASHBOARD_LINK, id)
        .then(({ msg }) => {
          ElMessage({
            message: msg,
            type: "success",
          });
        })
        .then(() => closeDialog())
        .then(() => store.dispatch(Actions.GET_DASHBOARD_LINK_LIST));
    };
    return {
      checkPermission,
      isSubmitting,
      closeDialog,
      openDialog,
      selectedData,
      dialogState,
      remove,
      headerCellClassName,
      tableData,
      openDrawer,
      handleDrawerClose,
      DashLinkDrawerMode,
      drawerState,
      drawerMode,
      isFetching,
      store,
    };
  },
});
