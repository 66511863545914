
import { computed, defineComponent, reactive, ref, onMounted } from "vue";
import type { ElForm } from "element-plus";
import { resetForm } from "@/core/helpers/helps";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { DashLinkDrawerMode } from "@/store/modules/DashboardLinkModule";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "component-add-new-dashboard-link-form",
  setup() {
    const formModel = reactive({
      url: "",
      page: "",
      tenants: [],
    });
    const formRules = reactive({});
    const store = useStore();
    const tenants = ref([]);
    const formRef = ref<InstanceType<typeof ElForm>>();

    onMounted(() => {
      getTenants();
    });

    const isSubmitting = computed(
      () => store.getters.dashboardLinkIsSubmitting
    );

    const submitForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.STORE_DASHBOARD_LINK, formModel)
            .then(({ msg }) => {
              ElMessage({
                message: msg,
                type: "success",
              });
              resetForm(formRef.value);
              store.dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE, false);
              store.dispatch(
                Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE,
                DashLinkDrawerMode.DEFAULT
              );
              store.dispatch(Actions.GET_DASHBOARD_LINK_LIST);
            })
            .catch((error) => {
              ElMessage({
                message: error,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    };

    const getTenants = () => {
      store.dispatch(Actions.GET_TENANTS).then((res) => {
        tenants.value = res.data;
      });
    };

    return {
      isSubmitting,
      submitForm,
      resetForm,
      formRef,
      tenants,
      formRules,
      formModel,
    };
  },
});
