
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import type { ElForm } from "element-plus";
import { resetForm } from "@/core/helpers/helps";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {
  DashLinkDrawerMode,
  IDashboardLink,
} from "@/store/modules/DashboardLinkModule";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "component-edit-dashboard-link-form",
  props: {
    dashboardLink: {
      type: Object as PropType<IDashboardLink>,
      required: true,
    },
  },
  setup(props) {
    const formModel = reactive({
      id: "",
      url: "",
      page: "",
      tenants: [],
    });
    const formRules = reactive({});
    const store = useStore();
    const formRef = ref<InstanceType<typeof ElForm>>();
    const tenants = ref([]);

    onMounted(() => {
      getDetail(props.dashboardLink.id);
      getTenants();
    });

    const isSubmitting = computed(
      () => store.getters.dashboardLinkIsSubmitting
    );
    const isFetching = computed(() => store.getters.dashboardLinkIsFetching);

    const submitForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.UPDATE_DASHBOARD_LINK, formModel)
            .then(({ msg }) => {
              ElMessage({
                message: msg,
                type: "success",
              });
              resetForm(formRef.value);
              store.dispatch(Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE, false);
              store.dispatch(
                Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE,
                DashLinkDrawerMode.DEFAULT
              );
              store.dispatch(Actions.GET_DASHBOARD_LINK_LIST);
            })
            .catch((error) => {
              ElMessage({
                message: error,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    };

    const getDetail = (id) => {
      if (!id) return false;
      store
        .dispatch(Actions.GET_DASHBOARD_LINK_DETAIL, id)
        .then((data) => {
          formModel.url = data.data.url;
          formModel.page = data.data.page;
          formModel.id = data.data.id;
          formModel.tenants = data.data.tenants;
        })
        .catch((error) => {
          ElMessage({
            message: error,
            type: "error",
          });
        });
    };

    const getTenants = () => {
      store.dispatch(Actions.GET_TENANTS).then((res) => {
        tenants.value = res.data;
      });
    };

    return {
      isFetching,
      isSubmitting,
      submitForm,
      resetForm,
      formRef,
      tenants,
      formRules,
      formModel,
    };
  },
});
